const preloader = document.getElementById("preloaderContainer");

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

async function removePreloader() {
  preloader.style.opacity = "0";
  await delay(300);
  preloader.remove();
}

function toggleSpinner() {
  const label = document.getElementById('submitLabel');
  const spinner = document.getElementById('spinner');

  console.log(label);
  console.log(spinner);

  label.classList.toggle('d-none');
  spinner.classList.toggle('d-none');
}
